@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

@font-face {
  font-family: "Love";
  src: url("../fonts/Love.otf");
}
@layer base {
  html {
    @apply scroll-smooth;
  }
}

.loading-spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1890ff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.review-slider {
  margin: -1rem -1rem 0;
  padding: 1rem;
}

.review-slider .slick-track {
  display: flex !important;
}

.review-slider .slick-slide {
  height: inherit !important;
  padding: 1rem;
}

.review-slider .slick-slide > div {
  height: 100%;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* Additional styles for the enhanced design */
.aspect-w-3 {
  position: relative;
  padding-bottom: 133.333333%;
}

.aspect-w-3 > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Smooth scrolling for the photo gallery */
.fixed.inset-0 {
  scroll-behavior: smooth;
}

/* Enhanced hover effects */
.hover\:scale-105:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

/* Gradient backgrounds */
.bg-gradient-to-r {
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.review-slider .slick-slide:hover > div > div {
  animation: float 3s ease-in-out infinite;
}

/* Custom scrollbar for review images */
.review-slider .overflow-x-auto {
  scrollbar-width: thin;
  scrollbar-color: #CBD5E0 #EDF2F7;
}

.review-slider .overflow-x-auto::-webkit-scrollbar {
  height: 6px;
}

.review-slider .overflow-x-auto::-webkit-scrollbar-track {
  background: #EDF2F7;
  border-radius: 3px;
}

.review-slider .overflow-x-auto::-webkit-scrollbar-thumb {
  background-color: #CBD5E0;
  border-radius: 3px;
}

.puppy-slider .slick-dots {
  bottom: -30px;
}

.puppy-slider .slick-dots li button:before {
  font-size: 12px;
  color: #6B7280;
}

.puppy-slider .slick-dots li.slick-active button:before {
  color: #3B82F6;
}

.review-slider {
  margin: 0 -1rem;
}

.review-slider .slick-slide {
  padding: 1rem;
}

.description-modal .ant-modal-content {
  border-radius: 20px;
  padding: 24px;
}

.description-modal .ant-modal-header {
  border-bottom: none;
  padding: 0;
}

.description-modal .ant-modal-body {
  padding: 16px 0 0;
}

.description-modal .ant-modal-close {
  top: 24px;
  right: 24px;
}
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

h6 {
  font-family: "Love";
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.nunito-sans {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

input[type="checkbox"] {
  accent-color: #001219;
  zoom: 1.5;
}

.ant-select-selection {
  background-color: green;
}

.ag-theme-quartz {
  --ag-foreground-color: rgb(126, 46, 132);
  --ag-background-color: rgb(249, 245, 227);
  --ag-header-foreground-color: rgb(204, 245, 172);
  --ag-header-background-color: rgb(209, 64, 129);
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: rgb(126, 46, 132);

  --ag-font-size: 17px;
  --ag-font-family: monospace;
}

.ag-header {
  --ag-header-height: 30px;
  --ag-header-background-color: white;
  font-family: "Nunito Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 6px; /* Width of vertical scrollbar */
  max-height: 10px; /* Height of horizontal scrollbar */
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #b4b4b4;
  border-radius: 10px;
}

/* Scrollbar thumb hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-scrollbar {
  -ms-overflow-style: none; 
  scrollbar-width: none;
}

.editor-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.editor-wrapper {
  flex-grow: 1;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f9f9f9;
}

.ql-container.ql-snow {
  border: none;
  font-size: 16px;
  height: calc(100% - 42px); /* Adjust based on your toolbar height */
}

.ql-editor {
  padding: 20px;
  min-height: 300px;
}

.ql-editor p {
  margin-bottom: 1em;
}

/* Custom styles for toolbar buttons */
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #4a90e2;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: #4a90e2;
}

.ql-snow .ql-toolbar button:hover,
.ql-snow .ql-toolbar button:focus,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #4a90e2;
}

.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #4a90e2;
}

.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #4a90e2;
}

/* Swiper Slider */

.swiper-pagination-bullet {
  background-color: #cccccc; /* Light gray */
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #3182ce; /* Tailwind's blue-600 */
}